import { IconEciu, IconEciuUniversity } from "@/components/Icons";
import { mcn } from "@/utils";
import { FooterStaticData } from "@/_staticContent/footer";
import { CookieConsent } from "@/utils/cookies/cookies";
import { CustomizeCookiesDialog } from "@/components/Cookie/CustomizeCookiesDialog";
import { PropsWithChildren, useState } from "react";
import { ContactUsButton } from "@/components/ContactUsButton";
import Link from "@/components/Link";
import Image from "next/image";
import FundedByEuImg from "../public/images/funded-by-eu.png";
import { Liability } from "@/server/backend-api/liability";
import IconActionEnvelope from "@/components/Icons/IconActionEnvelope";
import IconFacebook from "@/components/Icons/IconFacebook";
import IconX from "@/components/Icons/IconX";
import IconLinkedIn from "@/components/Icons/IconLinkedIn";
import IconInstagram from "./Icons/IconInstagram";

function FooterTextSection(
  props: PropsWithChildren<{
    text: string;
    icon: "eciu" | "eciuUniversity" | "eu";
    className?: string;
  }>
) {
  return (
    <div className={mcn("flex flex-col justify-start", props.className)}>
      <div className="h-18">
        {props.icon === "eu" ? (
          <div className={"w-56"}>
            <Image
              alt={
                "The flag of the European Union and that that says 'Co-funded by the European Union'"
              }
              src={FundedByEuImg}
              objectFit={"fill"}
            ></Image>
          </div>
        ) : null}
        {props.icon === "eciu" ? <IconEciu className="w-18 h-14" /> : null}
        {props.icon === "eciuUniversity" ? (
          <IconEciuUniversity className="w-20 h-14" />
        ) : null}
      </div>
      <p className="text-style-body2">{props.text}</p>
      {props.children}
    </div>
  );
}

interface Props {
  onCookieConsentSaved: (consent: CookieConsent) => void;
  termsAndConditions: Liability | null;
}

export function Footer(props: Props) {
  const [showCustomizeCookieDialog, setShowCustomizeCookieDialog] =
    useState(false);
  return (
    <div
      className={
        "w-full m-0 flex justify-center overflow-hidden border-t border-grey-20"
      }
    >
      <footer className={mcn("bg-white container grid grid-cols-12 mx-0")}>
        {/* Text Section */}
        <FooterTextSection
          className="col-span-full order-2 laptop-lg:order-none tablet:col-span-6 laptop-lg:col-span-3 m-md mt-lg laptop-lg:my-3xl"
          text={FooterStaticData.eciuText}
          icon="eciu"
        >
          <div className={"mt-md laptop-lg:mt-3xl flex space-x-sm"}>
            <a href="mailto:katrin.dircksen@eciu.eu">
              <IconActionEnvelope
                className={
                  "h-10 w-10 bg-white rounded-full hover:bg-yellow-100"
                }
                aria-hidden
              />
              <span className="sr-only">Email ECIU</span>
            </a>
            <a href="https://www.facebook.com/eciuorg">
              <IconFacebook
                className={
                  "h-10 w-10 bg-white rounded-full hover:bg-yellow-100"
                }
                aria-hidden
              />
              <span className="sr-only">ECIU Facebook</span>
            </a>
            <a href="https://twitter.com/eciuniversities">
              <IconX
                className={
                  "h-10 w-10 bg-white rounded-full hover:bg-yellow-100"
                }
                aria-hidden
              />
              <span className="sr-only">ECIU twitter</span>
            </a>
            <a href="https://www.linkedin.com/company/eciu">
              <IconLinkedIn
                className={
                  "h-10 w-10 bg-white rounded-full hover:bg-yellow-100"
                }
                aria-hidden
              />
              <span className="sr-only">ECIU LinkedIn</span>
            </a>
            <a href="https://www.instagram.com/eciuuniversity/">
              <IconInstagram
                className={
                  "h-10 w-10 bg-white rounded-full hover:bg-yellow-100"
                }
                aria-hidden
              />
              <span className="sr-only">ECIU Instagram</span>
            </a>
          </div>
        </FooterTextSection>
        <FooterTextSection
          className="col-span-full order-3 laptop-lg:order-none tablet:col-span-6 laptop-lg:col-span-3 m-md mt-lg laptop-lg:my-3xl"
          text={FooterStaticData.eciuUniversityText}
          icon="eciuUniversity"
        />
        <FooterTextSection
          className="col-span-full order-4 laptop-lg:order-none tablet:col-span-6 laptop-lg:col-span-3 m-md mt-lg laptop-lg:my-3xl"
          text={FooterStaticData.euText}
          icon="eu"
        />
        <div
          style={{
            // Offset BG image slightly to the right.
            backgroundPositionX: 24,
          }}
          className={mcn(
            "col-span-full order-1 laptop-lg:order-none tablet:col-span-6 laptop-lg:col-span-3 m-md mt-lg laptop-lg:mt-3xl",
            // Hack that allows BG image to overflow the container element.
            // Adjust these values to move the HeaderLogo further down or up.
            "laptop-lg:pb-26 laptop-lg:-mb-26",
            "laptop-lg:w-[600px] bg-none laptop-lg:bg-eciu-polygon-header",
            "bg-no-repeat bg-right-bottom bg-clip-content"
          )}
        >
          <div className="flex flex-col gap-y-xs laptop-lg:h-[300px] laptop-lg:pl-xl laptop-lg:border-l border-grey-20">
            <Link className="text-style-link1 w-fit" href="/about-why-how">
              About
            </Link>
            <ContactUsButton className="w-fit" text="Contact us" />
            <Link className="text-style-link1 w-fit" href="/faq">
              FAQ
            </Link>
            <button
              className="text-style-link1 cursor-pointer text-left w-fit"
              onClick={() => setShowCustomizeCookieDialog(true)}
            >
              Manage my cookies
            </button>
            <Link
              className="text-style-link1 w-fit"
              href="https://www.eciu.eu/forms/newsletter-subscription"
              prefetch={false}
            >
              Newsletter
            </Link>
            <Link
              className="text-style-link1 w-fit"
              href={props.termsAndConditions?.url ?? "/404"}
            >
              Terms and conditions
            </Link>
          </div>
        </div>
        <CustomizeCookiesDialog
          open={showCustomizeCookieDialog}
          onClose={() => setShowCustomizeCookieDialog(false)}
          onCookieConsentSaved={(consent) => {
            props.onCookieConsentSaved(consent);
            setShowCustomizeCookieDialog(false);
          }}
          termsAndConditions={props.termsAndConditions}
        />
      </footer>
    </div>
  );
}
