import { SVGProps } from "react";

const SvgIconActionEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0C23.9556 0 27.8224 1.17298 31.1114 3.37061C34.4004 5.56824 36.9638 8.69181 38.4776 12.3463C39.9913 16.0009 40.3874 20.0222 39.6157 23.9018C38.844 27.7814 36.9392 31.3451 34.1421 34.1421C31.3451 36.9392 27.7814 38.844 23.9018 39.6157C20.0222 40.3874 16.0009 39.9913 12.3463 38.4776C8.69181 36.9638 5.56824 34.4004 3.37061 31.1114C1.17298 27.8224 0 23.9556 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0ZM10.0033 12.3698L20.1823 20.6608L29.9186 12.3698H10.0033ZM9.35221 26.3314L16.6016 19.0592L9.3457 13.1478V26.3249L9.35221 26.3314ZM17.3926 19.7103L9.92188 27.1973H30.0163L22.8743 19.7103L20.5078 21.7155C20.4162 21.7912 20.3011 21.8326 20.1823 21.8326C20.0635 21.8326 19.9484 21.7912 19.8568 21.7155L17.3861 19.7005L17.3926 19.7103ZM23.6556 19.0592L30.6543 26.3965V13.0827L23.6556 19.0462V19.0592Z"
      fill="#002337"
    />
  </svg>
);

export default SvgIconActionEnvelope;
