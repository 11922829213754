import TextField from "@/components/Input/TextField";
import Dropdown from "@/components/Input/Dropdown";
import Button from "@/components/Button";
import { useState } from "react";
import { SupportBody } from "../../pages/api/support";
import { Assert } from "@/utils/assert";
import { useLogging } from "@/utils/appInsights/client";
import { mcn } from "@/utils";
import { validateEmailAddress } from "@/utils/email";
import { OrganisationBase } from "@/server/backend-api/organisation";
import { ContactFormSetting } from "@/server/backend-api/generated";
import Link from "../Link";
import { LearningOpportunityName } from "@/server/backend-api";
import { ProfileData } from "../../types";
import AutoComplete from "../Input/AutoComplete";

interface FormState {
  userFullName?: string;
  userEmailAddress?: string;
  userHomeUniversityId?: string | null;
  contactFormSetting?: ContactFormSetting;
  description?: string;

  /**
   * If topic refers to a learning opportunity, this is the id of that learning opportunity.
   */
  learningOppId?: string;
}

export type Presets = Pick<FormState, 'learningOppId' | 'contactFormSetting'>;

export interface ContactUsFormProps {
  universities: OrganisationBase[];
  supportSettings: ContactFormSetting[];
  learningOppNames: LearningOpportunityName[];
  onSendStateChanged: (newState: "loading" | "error" | "success") => void;
  profile?: ProfileData;
  title?: string;
  description?: string;
  presets?: Presets;
  formClassNames?: string;
}

export function ContactUsForm(props: ContactUsFormProps) {
  const { profile } = props;
  const [formState, setFormState] = useState<FormState>({
    userHomeUniversityId: profile?.homeOrganisationId,
  });
  const { logException, logErrorTrace } = useLogging();
  const isAuthenticated = profile !== undefined;


  const {
    title = "Contact us",
    description = "Facing any issues? Let us know via this form! We will get back to you as soon as possible.",
    presets,
    universities,
  } = props;

  // Equals the current form state, but override/add any preset values.
  const mergedValues: FormState = {
    ...formState,
    ...presets,
  };

  async function onSubmitRequest() {
    const body: SupportBody = {};
    if (isAuthenticated) {
      Assert.check(
        mergedValues.contactFormSetting !== undefined &&
          mergedValues.description !== undefined
      );
      body.authenticatedSupportRequest = {
        contactFormSettingId: mergedValues.contactFormSetting.id,
        request: mergedValues.description,
        learningOpportunityId: mergedValues.learningOppId ?? null,
      };
    } else {
      Assert.check(
        !!mergedValues.userFullName &&
          !!mergedValues.userEmailAddress &&
          !!mergedValues.userHomeUniversityId &&
          !!mergedValues.contactFormSetting &&
          !!mergedValues.description
      );
      body.supportRequest = {
        userFullName: mergedValues.userFullName,
        userEmailAddress: mergedValues.userEmailAddress,
        userHomeUniversityId: mergedValues.userHomeUniversityId,
        contactFormSettingId: mergedValues.contactFormSetting.id,
        request: mergedValues.description,
        learningOpportunityId: mergedValues.learningOppId ?? null,
      };
    }

    props.onSendStateChanged("loading");
    try {
      const res = await fetch("/api/support", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      if (!res.ok) {
        logErrorTrace("Non-OK status code from ContactUsDialog API call.", {
          httpStatusCode: res.status,
        });
        props.onSendStateChanged("error");
        return;
      }
      props.onSendStateChanged("success");
    } catch (err) {
      logException(err, {
        message: "ContactUsDialog error from API call.",
      });
      props.onSendStateChanged("error");
    } finally {
      setFormState({});
    }
  }

  function getFormLayout() {
    
    const contactFormSetting = mergedValues.contactFormSetting;
    const relatedFields = contactFormSetting?.additionalFields ?? [];

    // TODO: Change this to the actual string value sent by BE.
    const learningOppRelatedName = "ELO";
    const enableLearningOppId = relatedFields.includes(learningOppRelatedName) || mergedValues.learningOppId !== null;
    return {
      // Only enable title field for topics that have the related field LO setting enabled.
      enableLearningOppId: enableLearningOppId,
    };
  }

  function getFormValidation() {
    const { enableLearningOppId } = getFormLayout();
    const isEmailValid = validateEmailAddress(
      mergedValues.userEmailAddress ?? ""
    );
    const loNotRequiredOrLoSelected =
      !enableLearningOppId ||
      (enableLearningOppId && mergedValues.learningOppId !== undefined);
    if (isAuthenticated) {
      return {
        isEmailValid: isEmailValid,
        showEmailError:
          mergedValues.userEmailAddress !== undefined && !isEmailValid,
        isFormValid:
          mergedValues.contactFormSetting !== undefined &&
          mergedValues.description !== undefined &&
          loNotRequiredOrLoSelected,
      };
    } else {
      return {
        isEmailValid: isEmailValid,
        showEmailError:
          mergedValues.userEmailAddress !== undefined && !isEmailValid,
        isFormValid:
          isEmailValid &&
          mergedValues.userFullName !== undefined &&
          mergedValues.userEmailAddress !== undefined &&
          mergedValues.userHomeUniversityId !== undefined &&
          mergedValues.contactFormSetting !== undefined &&
          mergedValues.description !== undefined &&
          loNotRequiredOrLoSelected,
      };
    }
  }

  const { isFormValid, showEmailError } = getFormValidation();
  const layout = getFormLayout();
  return (
    <>
      <div>
        <div className={mcn("bg-white text-blue flex flex-col")}>
          <h3 className="text-style-headline4 flex justify-center mt-md">
            {title}
          </h3>
          <p className="mt-6">{description}</p>
          {!isAuthenticated && (
            <>
              <p className="flex justify-center mt-6">- OR - </p>
              <p className={"mt-6 flex justify-center gap-x-1"}>
                <Link href={"/signin"}>Sign in</Link>
                <span>for quicker and tailored support</span>
              </p>
            </>
          )}
          <div className="flex flex-col gap-y-4 mt-6">
            {isAuthenticated ? (
              <TextField
                value={profile?.displayName ?? ""}
                disabled={true}
                labelProps={{
                  text: "Name",
                }}
              />
            ) : (
              <TextField
                value={mergedValues.userFullName}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    userFullName: e.currentTarget.value,
                  })
                }
                labelProps={{
                  text: "Name",
                }}
              />
            )}

            {isAuthenticated ? (
              <TextField
                value={profile?.username ?? ""}
                disabled={true}
                labelProps={{
                  text: "Email",
                }}
              />
            ) : (
              <TextField
                state={showEmailError ? "error" : "neutral"}
                messages={{
                  error: showEmailError
                    ? ["Enter a valid email address"]
                    : undefined,
                }}
                value={mergedValues.userEmailAddress}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    userEmailAddress: e.currentTarget.value,
                  })
                }
                labelProps={{
                  text: "Email",
                }}
              />
            )}
            {!isAuthenticated ? (
              <>
                <span className="text-style-caption">
                  {
                    "Please use your ECIU University email if you have one. It'll help us provide even swifter, more personalized support"
                  }
                </span>
                <Dropdown
                  labelProps={{
                    text: "Home university",
                  }}
                  onChange={(uni) => {
                    setFormState({
                      ...formState,
                      userHomeUniversityId: uni?.data,
                    });
                  }}
                  items={universities.map((uni) => ({
                    text: uni.name ?? "",
                    id: uni.id,
                    data: uni.id,
                  }))}
                />
              </>
            ): (
              <>
                <span className="text-style-caption">
                  {
                    "Please use your ECIU University email if you have one. It'll help us provide even swifter, more personalized support"
                  }
                </span>
                <Dropdown
                  labelProps={{
                    text: "Home university",
                  }}
                  onChange={(uni) => {
                    setFormState({
                      ...formState,
                      userHomeUniversityId: uni?.data,
                    });
                  }}
                  items={universities.map((uni) => ({
                    text: uni.name ?? "",
                    id: uni.id,
                    data: uni.id,
                    preSelected: profile?.homeOrganisationId === uni.id? true : false,
                  }))}
                  
                />
              </>
            )}
            <div className="border-t border-grey-40 mt-2 mb-2"></div>
            <Dropdown
              onChange={(supportSetting) =>
                setFormState({
                  ...formState,
                  contactFormSetting: supportSetting?.data,
                })
              }
              labelProps={{
                text: "Choose the option that best matches your issue",
              }}
              items={props.supportSettings.map((setting) => ({
                text: setting.name ?? "",
                id: setting.id,
                data: setting,
                preSelected: props.presets?.contactFormSetting === setting ? true : false,
              }))}
            />

            {layout.enableLearningOppId && (
              <AutoComplete
                labelProps={{
                  text: "The name of the learning opportunity",
                }}
                items={props.learningOppNames.map((x) => ({
                  id: x.id,
                  text: x.name,
                  disabled: false,
                  data: x,
                  preSelected: x.id === props.presets?.learningOppId ? true : false,
                }))}
                onChange={(item) => {
                  setFormState({
                    ...formState,
                    learningOppId: item?.data.id,
                  });
                }}
                onRenderItem={(item) => {
                  return (
                    <div className="flex flex-col">
                      <span>{item.data.name}</span>
                      <span className="text-style-caption">
                        {item.data.studyPeriod}
                      </span>
                    </div>
                  );
                }}
              />
            )}
            <TextField
              value={mergedValues.description}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  description: e.currentTarget.value,
                })
              }
              labelProps={{
                text: "Describe the issue you are facing",
              }}
              useTextArea={true}
            />
          </div>

          <div className="flex justify-end mt-6">
            <Button
              kind="primary"
              label="Send"
              disabled={!isFormValid}
              onClick={onSubmitRequest}
            />
          </div>
        </div>
      </div>
    </>
  );
}
